<!--suppress SpellCheckingInspection -->
<template>
  <div style="margin-top: 34px">
    <el-row type="flex">
      <el-col :span="18" :offset="3">
        <div style="display: flex">
          <PageHeader :title="__('API Documentation')"></PageHeader>
          <el-button @click="downloadPostmanCollection" style="border: none;"
            ><img
              :src="require('@/assets/images/postman.png')"
              height="50px"
              alt="postman_download"
            />
            <div style="margin-top: 15px">
              {{ __("Download Postman Collection") }}
            </div>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" v-if="campaign.campaign_type === 'On-Demand'">
      <el-col :span="18" :offset="3">
        <on-demand-campaign-api-info :campaign="campaign" />
      </el-col>
    </el-row>
    <el-row type="flex" v-else>
      <el-col :span="18" :offset="3">
        <list-based-campaign-api-info :campaign="campaign" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import OnDemandCampaignApiInfo from "@/views/build/campaigns/components/OnDemandCampaignApiInfo";
import ListBasedCampaignApiInfo from "@/views/build/campaigns/components/ListBasedCampaignApiInfo";
import { getPostmanCollection } from "@/api/campaigns";
import FileDownloader from "@/mixins/FileDownloader";
import _ from "lodash";

export default {
  name: "apiInfo",
  mixins: [FileDownloader],
  components: {
    ListBasedCampaignApiInfo,
    OnDemandCampaignApiInfo,
    PageHeader
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  methods: {
    downloadPostmanCollection() {
      getPostmanCollection(this.campaign).then(res => {
        this.downloadFile(
          res.data,
          _.snakeCase(this.campaign.campaign_name) + ".postman_collection.json"
        );
      });
    }
  }
};
</script>

<style scoped>
.api-documentation-container {
  position: relative;
  margin: 15px 0;
  padding: 39px 19px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.api-documentation-container::after {
  content: "API Documentation";
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #9da0a4;
  -webkit-border-radius: 4px 0 4px 0;
  -moz-border-radius: 4px 0 4px 0;
  border-radius: 4px 0 4px 0;
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table th {
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table .table {
  background-color: #ffffff;
}

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}

.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}

.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
}

.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}

.prettyprint {
  padding: 12px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 6px;
  list-style: none;
}
</style>
