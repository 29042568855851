<template>
  <div class="api-documentation-error-container">
    <h3>{{ __("Errors") }}</h3>
    <p>
      <!-- eslint-disable-next-line -->
      {{ __("The following table describes the error codes which may appear when working with the API") }}:
    </p>
    <div class="row">
      <div class="span8">
        <table class="table table- table-bordered table-striped">
          <thead>
            <tr>
              <th style="width:15%;">{{ __("Error Code") }}</th>
              <th>{{ __("Error Message") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>200</td>
              <td>
                <span v-if="!isChatBotTask">{{ __("Call") }}</span
                ><span v-else>{{ __("Chat") }}</span>
                {{ __("details have been saved.") }}
              </td>
            </tr>
            <tr>
              <td>403</td>
              <td>{{ __("Campaign Key not found.") }}</td>
            </tr>
            <tr>
              <td>403</td>
              <td>{{ __("User API Key is required.") }}</td>
            </tr>
            <tr>
              <td>403</td>
              <td>
                {{
                  __("API key does not have permission to perform this action.")
                }}
              </td>
            </tr>
            <tr>
              <td>405</td>
              <td>{{ __("HTTP POST method is supported only.") }}</td>
            </tr>
            <tr>
              <td>422</td>
              <td>
                <strong>{{ __("Please note") }}: </strong>
                <!-- eslint-disable-next-line -->
                {{ __("While the text for an error message may change, the error code will stay the same.") }}
              </td>
            </tr>
            <tr>
              <td>500</td>
              <td>
                <!-- eslint-disable-next-line -->
                {{ __("Something is broken. Please contact your service provider.") }}
              </td>
            </tr>
            <tr>
              <td>501</td>
              <td>{{ __("Method not implemented.") }}</td>
            </tr>
            <tr>
              <td>504</td>
              <td>
                <!-- eslint-disable-next-line -->
                {{ __("The request couldn't be serviced due to some failure within our stack. Try again later.") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { TASK_TYPES } from "@/constants/nodes";

export default {
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  computed: {
    isChatBotTask() {
      return this.campaign.campaign_channel === TASK_TYPES.CHATBOT;
    }
  }
};
</script>

<style scoped>
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table th {
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table .table {
  background-color: #ffffff;
}

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}

.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}

.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
}

.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}
</style>
