<template>
  <div class="api-documentation-container">
    <h3>{{ __("Call URL") }}</h3>
    <p></p>
    <p>{{ `${backendUrl}/campaign/call` }}</p>
    <br />

    <h3>{{ __("HTTP Method") }}</h3>
    <p>{{ __("POST") }}</p>
    <br />

    <h3>{{ __("Headers") }}</h3>
    <br />

    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>
                <strong>{{ __("studio-api-key") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                {{ __("Any of the User API Key generated for user/account") }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <h3>{{ __("Parameters") }}</h3>
    <br />

    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>
                <strong>{{ __("campaign_key") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                {{ campaign.api_key }}
              </td>
            </tr>
            <tr v-if="!isChatBotTask">
              <td>
                <strong>{{ __("phone_number") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                <p>
                  <!-- eslint-disable-next-line -->
                  {{ __("The phone number to call. Phone numbers should not contain spaces or a '+' sign and country code.") }}
                </p>
                <p style="margin-top:10px;">
                  <strong>{{ __("Example Values") }}:</strong>
                </p>
                <ul>
                  <li>{{ __("Australian phone numbers") }}: 0370103212</li>
                  <li>{{ __("US phone numbers") }}: 2764775115</li>
                </ul>
              </td>
            </tr>
            <tr v-else>
              <td>
                <strong>{{ __("(parameters name)") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                <p>
                  <!-- eslint-disable-next-line -->
                  {{ __("For chatbot campaign you need pass at least one parameter") }}
                </p>
                <p style="margin-top:10px;">
                  <strong>{{ __("Example Values") }}:</strong>
                </p>
                <ul>
                  <li>{{ __("phone:[PASSED PARAMETER VALUE]") }}</li>
                </ul>
              </td>
            </tr>
            <tr v-if="!isChatBotTask">
              <td>
                <strong>{{ __("time_to_dial") }}</strong>
              </td>
              <td>
                <strong>{{ __("OPTIONAL") }}</strong>
              </td>
              <td>
                <p>
                  <!-- eslint-disable-next-line -->
                  {{ __("Date and time to call the phone number. Date must be in ISO-8601 format and encoded. When the date and time is not passed in the Resource URL, the call will be made immediately.") }}
                </p>
                <p style="margin-top:10px;">
                  <strong>{{ __("Example Value") }}:</strong>
                </p>
                <ul>
                  <li>
                    {{ __("ISO-8601 date") }}: {{ campaign.iso_date_example }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr v-if="!isChatBotTask">
              <td>
                <strong>{{ __("from_number") }}</strong>
              </td>
              <td>
                <strong>{{ __("OPTIONAL") }}</strong>
              </td>
              <td>
                <p>
                  {{ __("The") }} <strong>{{ __("Caller ID") }}</strong>

                  <!-- eslint-disable-next-line -->
                  {{ __("that will be sent as part of the outbound call. Please note, if you are running a Messaging Campaign, the caller id must be a valid number assigned to your account.") }}
                </p>
              </td>
            </tr>
            <tr v-if="!isChatBotTask">
              <td>
                <strong>{{ __("display_name") }}</strong>
              </td>
              <td>
                <strong>{{ __("OPTIONAL") }}</strong>
              </td>
              <td>
                <p>
                  {{ __("The") }} <strong>{{ __("display name") }}</strong>

                  <!-- eslint-disable-next-line -->
                  {{ __("that will be sent as part of the outbound call. This parameter is available for IVR campaigns.") }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <br />
    <h3>{{ __("Usage Notes") }}</h3>
    <ul>
      <li>
        <!-- eslint-disable-next-line -->
        {{ __("Parameters selected with the campaign can be passed as POST Data.") }}
      </li>
      <li>
        <!-- eslint-disable-next-line -->
        {{ __("API requests containing variables not selected with the campaign will not be accepted.") }}
      </li>
    </ul>

    <br />
    <h3>{{ __("Example Request") }}</h3>
    <ol class="prettyprint" style="position: relative">
      <li>
        curl --location --request POST "{{ this.backendUrl }}/campaign/call" \
      </li>
      <li>
        --header "Content-Type: application/x-www-form-urlencoded" \
      </li>
      <li>--header "studio-api-key: <b>YOUR STUDIO/USER API KEY</b>" \</li>
      <li>--data-urlencode "campaign_key={{ campaign.api_key }}" \</li>
      <li v-if="!isChatBotTask">
        --data-urlencode "phone_number=0370103212" \
      </li>
      <li
        v-if="campaign.campaign_passed_params.data.length > 0 && !isChatBotTask"
      >
        --data-urlencode "time_to_dial=2020-06-17T14:50:55+10:00" \
      </li>
      <li v-else-if="!isChatBotTask">
        --data-urlencode "time_to_dial=2020-06-17T14:50:55+10:00"
      </li>
      <li
        v-for="(passed_param_id, index) in campaign.campaign_passed_params.data"
        v-bind:key="passed_param_id"
      >
        --data-urlencode "{{ getParameterNameFromId(passed_param_id) }}=<b>{{
          __("PASSED PARAMETER VALUE (You need to pass at least 1 parameter)")
        }}</b
        >"
        <span v-if="index !== campaign.campaign_passed_params.data.length - 1">
          &nbsp;\
        </span>
      </li>
      <li style="position: absolute; top: 10px; right: 15px">
        <copier
          :value="getCurlCommandForCopy"
          :success-message-override="__('Curl command copied successfully')"
        ></copier>
      </li>
    </ol>
    <br />
    <h3>Response</h3>
    <p style="margin-top:10px;">
      {{ __("A response will be returned in JSON format.") }}
    </p>
    <br />
    <vue-json-pretty
      :data="successResponse"
      :highlightMouseoverNode="true"
      :highlightSelectedNode="true"
    >
    </vue-json-pretty>

    <br />
    <CampaignErrorCodeApiInfo :campaign="campaign" />
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import _ from "lodash";
import { mapGetters } from "vuex";
import Copier from "@/components/Copier";
import { TASK_TYPES } from "@/constants/nodes";
import CampaignErrorCodeApiInfo from "@/views/build/campaigns/components/CampaignErrorCodeApiInfo";

export default {
  components: {
    VueJsonPretty,
    Copier,
    CampaignErrorCodeApiInfo
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_BASE_API
    };
  },
  computed: {
    ...mapGetters("variables", {
      passedParameterVariables: "passedParameterVariables"
    }),

    successResponse() {
      let messageReturn = {
        response_code: "200",
        response_message: __("Call details have been saved.")
      };
      if (this.isChatBotTask) {
        messageReturn = {
          response_code: "200",
          response_message: __("Chatbot details have been saved.")
        };
      }
      return messageReturn;
    },
    getCurlCommandForCopy() {
      let curlCommand = `curl -location --request POST '${this.backendUrl}/campaign/call --header  'Content-Type: application/x-www-form-urlencoded' --data-urlencode 'ac_api_key=YOUR ACCOUNT API KEY' --data-urlencode 'campaign_key=${this.campaign.api_key}' --data-urlencode 'phone_number=0370103212' --data-urlencode 'time_to_dial=2020-06-17T14:50:55+10:00' `;
      _.forEach(this.campaign.campaign_passed_params.data, passed_param_id => {
        curlCommand += `--data-urlencode '${this.getParameterNameFromId(
          passed_param_id
        )}=PASSED PARAMETER VALUE' `;
      });
      return curlCommand;
    },
    isChatBotTask() {
      return this.campaign.campaign_channel === TASK_TYPES.CHATBOT;
    }
  },
  methods: {
    getParameterNameFromId(param_id) {
      return _.find(this.passedParameterVariables, variable => {
        return variable.variable_id === param_id;
      }).variable_name;
    }
  }
};
</script>

<style scoped>
.api-documentation-container {
  position: relative;
  margin: 15px 0;
  padding: 39px 19px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.api-documentation-container::after {
  content: "API Documentation";
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #9da0a4;
  -webkit-border-radius: 4px 0 4px 0;
  -moz-border-radius: 4px 0 4px 0;
  border-radius: 4px 0 4px 0;
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table th {
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table .table {
  background-color: #ffffff;
}

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}

.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}

.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
}

.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}

.prettyprint {
  padding: 12px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 6px;
  list-style: none;
}
</style>
