<template>
  <div class="api-documentation-container">
    <h2>{{ __("There are 4 APIs exposed for List Based Campaigns") }}</h2>
    <ol class="indent">
      <li>
        <el-button
          @click="scrollToElement('upload')"
          plain
          style="border: none"
        >
          {{ __("Upload") }}
          <span v-if="!isChatBotTask">{{ __("phone numbers") }}</span>
          <span v-else>{{ __("csv file") }}</span>
          {{ __("and passed parameters") }}
        </el-button>
      </li>
      <li>
        <el-button
          @click="scrollToElement('action')"
          plain
          style="border: none"
          >{{ __("Start, stop and reset a campaign") }}</el-button
        >
      </li>
      <li>
        <el-button
          @click="scrollToElement('schedule')"
          plain
          style="border: none"
          >{{
            __("Change the campaign schedule and after hours action")
          }}</el-button
        >
      </li>
    </ol>
    <br />
    <div v-if="!isChatBotTask">
      <h2 id="upload">1. {{ __("Upload the phone numbers") }}</h2>
    </div>
    <div v-else>
      <h2 id="upload">1. {{ __("Upload the csv file") }}</h2>
    </div>
    <h3>{{ __("Call URL") }}</h3>
    <p></p>
    <p>{{ getUploadUrl }}</p>

    <h3>{{ __("HTTP Method") }}</h3>
    <p>{{ __("POST") }}</p>

    <h3>{{ __("Headers") }}</h3>
    <br />

    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>
                <strong>{{ __("studio-api-key") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                {{ __("Any of the User API Key generated for user/account") }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <h3>{{ __("Parameters") }}</h3>

    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>
                <strong>{{ __("campaign_key") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                {{ campaign.api_key }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ __("file") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                <!-- eslint-disable-next-line -->
                {{ __("The CSV file with phone number and passed parameters to upload.") }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ __("insert_type") }}</strong>
              </td>
              <td>
                <strong>{{ __("OPTIONAL") }}</strong>
              </td>
              <td>
                {{ __("The insert type can be either") }}
                <b
                  ><i>{{ __("append") }}</i></b
                >
                {{ __("or") }}
                <b
                  ><i>{{ __("replace") }}</i></b
                >. <strong>{{ __("append") }}</strong>
                {{
                  __("is taken as the default value if no parameter is passed")
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <h4>{{ __("Usage Notes") }}</h4>
    <ul class="indent">
      <li>
        <!-- eslint-disable-next-line -->
        {{ __("API requests containing variables not selected with the campaign will not be accepted.") }}
      </li>
      <li>
        {{ __("Files with missing passed parameter will not be accepted") }}
      </li>
    </ul>

    <h3>{{ __("Example Request") }}</h3>
    <ol class="prettyprint" style="position: relative">
      <li>curl --location -XPOST {{ getUploadUrl }} \</li>
      <li>--header 'studio-api-key: <b>YOUR STUDIO/USER API KEY</b>' \</li>
      <li>--form 'campaign_key={{ campaign.api_key }}' \</li>
      <li>--form 'file=@<b>PATH TO CSV FILE</b>' \</li>
      <li>--form 'insert_type=replace'</li>
      <li style="position: absolute; top: 10px; right: 15px">
        <copier
          :value="getCurlCommandForUpload"
          :success-message-override="__('Curl command copied successfully')"
        ></copier>
      </li>
    </ol>

    <h3>{{ __("Response") }}</h3>
    <p style="margin-top:10px;">
      {{ __("A response will be returned in JSON format.") }}
    </p>
    <br />
    <vue-json-pretty
      :data="uploadSuccessResponse"
      :highlightMouseoverNode="true"
      :highlightSelectedNode="true"
    >
    </vue-json-pretty>

    <br />

    <br />
    <h2 id="action">2. {{ __("Start, stop and reset a campaign") }}</h2>
    <h3>{{ __("Call URL") }}</h3>
    <p></p>
    <p>{{ getTriggerUrl }}</p>

    <h3>{{ __("HTTP Method") }}</h3>
    <p>{{ __("POST") }}</p>

    <h3>{{ __("Headers") }}</h3>
    <br />

    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>
                <strong>{{ __("studio-api-key") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                {{ __("Any of the User API Key generated for user/account") }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <h3>{{ __("Parameters") }}</h3>

    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td><strong>campaign_key</strong></td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                {{ campaign.api_key }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ __("action") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                <!-- eslint-disable-next-line -->
                {{ __("action to be performed. can be 'start', 'stop', or 'reset'") }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <h3>{{ __("Example Request") }}</h3>
    <ol class="prettyprint" style="position: relative">
      <li>curl --location -XPOST {{ getTriggerUrl }} \</li>
      <li>--header 'studio-api-key: <b>YOUR STUDIO/USER API KEY</b>' \</li>
      <li>--header 'Content-Type: application/x-www-form-urlencoded' \</li>
      <li>--data-urlencode 'campaign_key={{ campaign.api_key }}' \</li>
      <li>--data-urlencode 'action=start' \</li>
      <li style="position: absolute; top: 10px; right: 15px">
        <copier
          :value="getCurlCommandForTrigger"
          :success-message-override="`Curl command copied successfully`"
        ></copier>
      </li>
    </ol>
    <h3>{{ __("Response") }}</h3>
    <p style="margin-top:10px;">
      {{ __("A response will be returned in JSON format.") }}
    </p>
    <br />
    <vue-json-pretty
      :data="triggerActionSuccessResponse"
      :highlightMouseoverNode="true"
      :highlightSelectedNode="true"
    >
    </vue-json-pretty>
    <br />
    <h2 id="schedule">
      3. {{ __("Change the campaign schedule and after hours action") }}
    </h2>

    <h3>{{ __("Call URL") }}</h3>
    <p></p>
    <p>{{ getChangeScheduleUrl }}</p>

    <h3>{{ __("HTTP Method") }}</h3>
    <p>{{ __("POST") }}</p>

    <h3>{{ __("Headers") }}</h3>
    <br />

    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>
                <strong>{{ __("studio-api-key") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                {{ __("Any of the User API Key generated for user/account") }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <h3>{{ __("Parameters") }}</h3>

    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>
                <strong>{{ __("campaign_key") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                {{ campaign.api_key }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ __("day") }}</strong>
              </td>
              <td>
                <strong>{{ __("REQUIRED") }}</strong>
              </td>
              <td>
                <!-- eslint-disable-next-line -->
                {{ __("Any day between monday and sunday. note: day names must be spelled out completely") }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ __("start_time") }}</strong>
              </td>
              <td>
                <strong>{{ __("OPTIONAL") }}</strong>
              </td>
              <td>
                <!-- eslint-disable-next-line -->
                {{ __("Start time must be in the format HH:MM. Other formats are not accepted. example") }}: <strong>09:30</strong>.

                <br />
                {{ __("Please note") }}:

                <!-- eslint-disable-next-line -->
                {{ __("if the start time is less than global start time then global start time will be assumed as start time") }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ __("end_time") }}</strong>
              </td>
              <td>
                <strong>{{ __("OPTIONAL") }}</strong>
              </td>
              <td>
                <!-- eslint-disable-next-line -->
                {{ __("End time must be in the format HH:MM. Other formats are not accepted. example") }}: <strong>20:30</strong>.

                <br />
                {{ __("Please note") }}:

                <!-- eslint-disable-next-line -->
                {{ __("if the end time is greater than global end time then global end time will be assumed as end time") }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ __("after_hour_action") }}</strong>
              </td>
              <td>
                <strong>{{ __("OPTIONAL") }}</strong>
              </td>
              <td>
                {{ __("After hours action can be") }}
                <strong>{{ __("stop") }}</strong> {{ __("or") }}
                <strong>{{ __("pause") }}</strong
                >.
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ __("skip") }}</strong>
              </td>
              <td>
                <strong>{{ __("OPTIONAL") }}</strong>
              </td>
              <td>
                {{ __("The skip value can be either") }} <b><i>0</i></b>
                {{ __("or") }} <b><i>1</i></b
                >.
                {{
                  __("When skip set to 1, the campaign will not run on the day")
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>

    <h4>{{ __("Usage Notes") }}</h4>
    <ul class="indent">
      <li>
        {{ __("Start and end time must be in 24 hour format") }}
      </li>
      <li>
        <!-- eslint-disable-next-line -->
        {{ __("If the hour value is only single digit then it should be prefixed with zero. Example 9:30 should be 09:30") }}
      </li>
      <li>
        <!-- eslint-disable-next-line -->
        {{ __("Request will be rejected if the start time is less than or equal to end time of the campaign") }}
      </li>
    </ul>

    <h3>{{ __("Example Request") }}</h3>
    <ol class="prettyprint" style="position: relative">
      <li>curl --location -XPOST {{ getChangeScheduleUrl }} \</li>
      <li>--header 'studio-api-key: <b>YOUR STUDIO/USER API KEY</b>' \</li>
      <li>--header 'Content-Type: application/x-www-form-urlencoded' \</li>
      <li>--data-urlencode 'campaign_key={{ campaign.api_key }}' \</li>
      <li>--data-urlencode 'day=friday' \</li>
      <li>--data-urlencode 'start_time=08:30' \</li>
      <li>--data-urlencode 'end_time=22:00' \</li>
      <li>--data-urlencode 'after_hour_action=stop' \</li>
      <li>--data-urlencode 'skip=0'</li>
      <li style="position: absolute; top: 10px; right: 15px">
        <copier
          :value="getCurlCommandForChangeSchedule"
          :success-message-override="__('Curl command copied successfully')"
        ></copier>
      </li>
    </ol>
    <h3>{{ __("Response") }}</h3>
    <p style="margin-top:10px;">
      {{ __("A response will be returned in JSON format.") }}
    </p>
    <br />
    <vue-json-pretty
      :data="scheduleUpdateSuccessResponse"
      :highlightMouseoverNode="true"
      :highlightSelectedNode="true"
    >
    </vue-json-pretty>
    <br />
    <div v-if="!isChatBotTask">
      <h2 id="thread_count">4. {{ __("Change campaign thread count") }}</h2>

      <h3>{{ __("Call URL") }}</h3>
      <p></p>
      <p>{{ getThreadsUrl }}</p>

      <h3>{{ __("HTTP Method") }}</h3>
      <p>{{ __("POST") }}</p>

      <h3>{{ __("Headers") }}</h3>
      <br />

      <el-row type="flex">
        <el-col>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr>
                <td>
                  <strong>{{ __("studio-api-key") }}</strong>
                </td>
                <td>
                  <strong>{{ __("REQUIRED") }}</strong>
                </td>
                <td>
                  {{ __("Any of the User API Key generated for user/account") }}
                </td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>

      <h3>{{ __("Parameters") }}</h3>

      <el-row type="flex">
        <el-col>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr>
                <td>
                  <strong>{{ __("campaign_key") }}</strong>
                </td>
                <td>
                  <strong>{{ __("REQUIRED") }}</strong>
                </td>
                <td>
                  {{ campaign.api_key }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ __("threads") }}</strong>
                </td>
                <td>
                  <strong>{{ __("REQUIRED") }}</strong>
                </td>
                <td>
                  <!-- eslint-disable-next-line -->
                  {{ __("must be greater than or equal to zero and less than or equal to the total threads assigned to account") }}
                </td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>

      <h3>{{ __("Example Request") }}</h3>
      <ol class="prettyprint" style="position: relative">
        <li>curl --location -XPOST {{ getThreadsUrl }} \</li>
        <li>--header 'studio-api-key: <b>YOUR STUDIO/USER API KEY</b>' \</li>
        <li>--header 'Content-Type: application/x-www-form-urlencoded' \</li>
        <li>--data-urlencode 'campaign_key={{ campaign.api_key }}' \</li>
        <li>--data-urlencode 'threads=50' \</li>
        <li style="position: absolute; top: 10px; right: 15px">
          <copier
            :value="getCurlCommandForChangeThread"
            :success-message-override="__('Curl command copied successfully')"
          ></copier>
        </li>
      </ol>
      <h3>{{ __("Response") }}</h3>
      <p style="margin-top:10px;">
        {{ __("A response will be returned in JSON format.") }}
      </p>
      <br />
      <vue-json-pretty
        :data="threadsUpdateSuccessResponse"
        :highlightMouseoverNode="true"
        :highlightSelectedNode="true"
      >
      </vue-json-pretty>
    </div>
    <br />
    <CampaignErrorCodeApiInfo :campaign="campaign" />
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import Copier from "@/components/Copier";
import { TASK_TYPES } from "@/constants/nodes";
import CampaignErrorCodeApiInfo from "@/views/build/campaigns/components/CampaignErrorCodeApiInfo";
import { appendSector } from "@/utils/url";

export default {
  name: "ListBasedCampaignApiInfo",
  components: {
    VueJsonPretty,
    Copier,
    CampaignErrorCodeApiInfo
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_BASE_API,
      uploadSuccessResponse: {
        message: __("campaign data uploaded, inserted: 2, failed: 0"),
        status_code: 200
      },
      triggerActionSuccessResponse: {
        // eslint-disable-next-line
        message: __("Your campaign has been queued and it will start dialing shortly"),
        dialing_hours: {
          display_text: __("less than a minute"),
          start_time: "2021-07-19T00:00:00+1000",
          end_time: "2021-07-19T23:55:00+1000"
        },
        status_code: 200
      },
      scheduleUpdateSuccessResponse: {
        message: __("schedule updated successfully"),
        status_code: 200
      },
      threadsUpdateSuccessResponse: {
        message: __("thread count updated successfully to 2"),
        status_code: 200
      },
      curlCommands: {
        upload: appendSector(
          window.location.hostname,
          process.env.VUE_APP_BACKEND_BASE_API,
          "/campaign/upload"
        ),
        trigger: appendSector(
          window.location.hostname,
          process.env.VUE_APP_BACKEND_BASE_API,
          "/campaign/trigger"
        ),
        changeSchedule: appendSector(
          window.location.hostname,
          process.env.VUE_APP_BACKEND_BASE_API,
          "/campaign/schedule"
        ),
        changeThread: appendSector(
          window.location.hostname,
          process.env.VUE_APP_BACKEND_BASE_API,
          "/campaign/threads"
        )
      }
    };
  },
  computed: {
    getCurlCommandForUpload() {
      return `curl --location -XPOST '${this.getUploadUrl} --header 'studio-api-key: YOUR STUDIO/USER API KEY' --form 'campaign_key=${this.campaign.api_key}' --form 'file=@PATH TO CSV FILE' --form 'insert_type=replace'`;
    },
    getCurlCommandForTrigger() {
      return `curl --location -XPOST '${this.getTriggerUrl} --header 'studio-api-key: YOUR STUDIO/USER API KEY' --header 'Content-Type: application/x-www-form-urlencoded' --data-urlencode 'campaign_key=${this.campaign.api_key}' --data-urlencode 'action=start'`;
    },
    getCurlCommandForChangeSchedule() {
      return `curl --location -XPOST '${this.getChangeScheduleUrl} --header 'studio-api-key: YOUR STUDIO/USER API KEY' --header 'Content-Type: application/x-www-form-urlencoded' --data-urlencode 'campaign_key=${this.campaign.api_key}' --data-urlencode 'day=friday' --data-urlencode 'start_time=08:30' --data-urlencode 'end_time=22:00' --data-urlencode 'after_hour_action=stop' --data-urlencode 'skip=0'`;
    },
    getCurlCommandForChangeThread() {
      return `curl --location -XPOST '${this.getThreadsUrl} --header 'studio-api-key: YOUR STUDIO/USER API KEY' --header 'Content-Type: application/x-www-form-urlencoded' --data-urlencode 'campaign_key=${this.campaign.api_key}' --data-urlencode 'threads=50'`;
    },
    isChatBotTask() {
      return this.campaign.campaign_channel === TASK_TYPES.CHATBOT;
    },
    getThreadsUrl() {
      return this.curlCommands.changeThread;
    },
    getUploadUrl() {
      return this.curlCommands.upload;
    },
    getTriggerUrl() {
      return this.curlCommands.trigger;
    },
    getChangeScheduleUrl() {
      return this.curlCommands.changeSchedule;
    }
  },
  methods: {
    scrollToElement(element) {
      const el = this.$el.querySelector(`#${element}`);

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
};
</script>

<style scoped>
.api-documentation-container {
  position: relative;
  margin: 15px 0;
  padding: 39px 19px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.api-documentation-container::after {
  content: "API Documentation";
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #9da0a4;
  -webkit-border-radius: 4px 0 4px 0;
  -moz-border-radius: 4px 0 4px 0;
  border-radius: 4px 0 4px 0;
}

.prettyprint {
  padding: 12px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 6px;
  list-style: none;
}
</style>
